// First we need to import axios.js
import axios from 'axios';
import { auth } from './firebase';

axios.defaults.headers.post["Content-Type"] = "application/json";
// Next we make an 'instance' of it
const instance = axios.create({
    // .. where we make our configurations
    baseURL: process.env.NEXT_PUBLIC_API_URL
});
// // Where you would set stuff like your 'Authorization' header, etc ...
// instance.defaults.headers.common['Authorization'] = auth?.currentUser?.getIdToken();

// // Also add/ configure interceptors && all the other cool stuff
instance.interceptors.request.use(async function (config) {
    // Do something before request is sent
    config.headers['Authorization'] = `Bearer ${await auth?.currentUser?.getIdToken() || localStorage.getItem('jwt')}`;
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

// instance.interceptors.request...

export default instance;