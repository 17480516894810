import ApiService from "./api-service";

export interface IQuizResponse {
    response: IQuizItem[];
    message: string;
}

export interface IQuizItem {
    id: number;
    title: string;
    question_statement: string;
    options: IQuizOption[];
}

export interface IQuizOption {
    id: number;
    title: string;
    description: string;
    icon_code?: string;
    image_url?: string;
    quiz_section_id: number;
    ref_id: number;
    ref_table: string;
    published: number;
    deleted_at?: Date;
}

export interface IQuizSaveRequest {
    options: QuizUserChoice[];
}

export interface QuizUserChoice {
    quizSectionId: number;
    optionId: number;
    userId: number;
}

export default class QuizService {
    constructor(private apiService: ApiService) {}

    getQuiz(): Promise<IQuizItem[]> {
        return this.apiService
            .get<IQuizResponse>("/quiz")
            .then((response) => response.response);
    }

    saveQuiz(quizChoices: QuizUserChoice[]): Promise<any> {
        return this.apiService.post<any, IQuizSaveRequest>("/quiz/", {
            options: quizChoices,
        });
    }
}
