import { createContext, ReactNode, useContext } from "react";
import ApiService from "../api-service";
import PackageService from "../package";
import ProfileService from "../profile";
import QuizService from "../quiz";
import StripeService from "../stripe-service";
import WishListService from "../wishlist";

interface ApiContext {
    profileService: ProfileService;
    wishListService: WishListService;
    packageService: PackageService;
    quizService: QuizService;
    stripeService: StripeService;
}

const popupContext = createContext(null);
const { Provider } = popupContext;

export function ApiProvider(props: { children: ReactNode }): JSX.Element {
    const _api = useApiProvider();
    return <Provider value={_api}>{props.children}</Provider>;
}

export const useApi: () => ApiContext = () => {
    return useContext(popupContext) as ApiContext;
};

const useApiProvider = (): ApiContext => {
    const apiService: ApiService = new ApiService();
    const profileService = new ProfileService(apiService);
    const wishListService = new WishListService(apiService);
    const packageService = new PackageService(apiService);
    const quizService = new QuizService(apiService);
    const stripeService = new StripeService();
    return {
        profileService,
        wishListService,
        packageService,
        quizService,
        stripeService,
    };
};
