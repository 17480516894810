import ApiService from "./api-service";

export interface IWishListResponse {
    data: IWishListItem[];
    pagination: IPagination;
}

export interface IPagination {
    total?: number;
    lastPage: number;
    perPage: string;
    currentPage: number;
    from: number;
    to: number;
}

export interface IWishListItem {
    id: number;
    userId: number;
    holidayId: number;
    created_at: string;
    name: string;
    intro: string;
    file_name: string;
    accomodation: string;
    locationText: string;
    alias: string;
}

export default class WishListService {
    constructor(private apiService: ApiService) {}

    getFavourites(page = 1, perPage = 10) {
        const userId = localStorage.getItem("userId");
        return this.apiService.get(
            `/wishlist?userId=${userId}&perPage=${perPage}&page=${page}&orderBy=created_at&order=desc`
        );
    }

    removeFavourite(favouriteId) {
        return this.apiService.delete(`/wishlist/${favouriteId}`);
    }
}
