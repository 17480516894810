import ApiService from "./api-service";
import { auth } from "./firebase";

export default class ProfileService {
    constructor(private apiService: ApiService) {}

    updateImage(absoluteUrl: string): Promise<any> {
        return this.apiService.post("/users", {
            firebaseId: auth.currentUser.uid,
            imageURL: absoluteUrl,
        });
    }

    getUser(): Promise<any> {
        return this.apiService.get(`/users/${auth.currentUser.uid}`);
    }

    registerUser(uid): Promise<number> {
        return this.apiService
            .post(`/users/`, {
                firebaseId: uid,
            })
            .then((data) => data.response.id);
    }
}
