import ApiService from "./api-service";
import { PaginatedResponse } from "./gql/queries/types";
import apolloClient from "./gql/apollo";
import { gql } from "@apollo/client";

export interface IPackageListResponse {
    response: PaginatedResponse<IPackageListItem>;
    message: string;
}

export interface IPackageListItem {
    id: number;
    asset_id: number;
    code: string;
    title: string;
    name: string;
    alias: string;
    title_alias: string;
    intro: string;
    description: string;
    access: number;
    metadata: string;
    featured: number;
    language: string;
    xreference: string;
    categories: string;
    ordering: number;
    created_at: string;
    created_by: number;
    modified_at: string;
    modified_by: number;
    state: number;
    publish_up: string;
    publish_down: string;
    checked_out_by: number;
    checked_out_at: string;
    meta_title: string;
    meta_key: string;
    meta_description: string;
    canonical_url: string;
    videos: string;
    template: string;
    mediaxreference: string;
    key_image: string;
}

export interface IPackageDetailResponse {
    response: IPackageDetail;
    message: string;
}

export interface IPackageDetail {
    id: string;
    asset_id: string;
    code: string;
    title: string;
    name: string;
    alias: string;
    title_alias: string;
    intro: string;
    description: string;
    metadata: string;
    featured: string;
    xreference: string;
    ordering: string;
    created_at: string;
    modified_at: string;
    checked_out_at: string;
    meta_key: string;
    canonical_url: string;
    videos: string;
    template: string;
    mediaxreference: string;
    text: string;
    versions: string;
    copyItemsByCategory: CopyItemObject;
    other_versions: [OtherVersions];
    jcfields: [string];
    accommodations: [Accommodations];
    images: [Images];
    locations: [Locations];
    private_dates: [string];
    dates: [Dates];
    from_price: FromPrice;
    default_version: DefaultVersion;
    categories: [Categories];
}

export interface CopyItem {
    category_id: number;
    category_title: string;
    ordering: number;
    copy_item_id: number;
    title: string;
    content: string;
    display_title: number;
    state: number;
    language: string;
    usage_state: number;
}

export interface Type {
    id: string;
    name: string;
    title: string;
    description: string;
    is_from_price: string;
    min_age: string;
    max_age: string;
    default_start_date_offset: string;
    default_end_date_offset: string;
    state: string;
    ordering: string;
    is_supplement: string;
}

export interface Price {
    id: string;
    currency_code: string;
    currency_symbol: string;
    value: string;
    previous_value: string;
    additional_value: string;
    spaces: string;
    start_date_offset: string;
    end_date_offset: string;
    version_id: string;
    type: Type;
}

export interface ToPrice {
    price: Price;
}

export interface FromPrice {
    price: Price;
}

export interface Snippets {
    heading: string;
    text: string;
}

export interface Metadata {
    snippets: [Snippets];
}

export interface OtherVersions {
    id: string;
    name: string;
    title: string;
    state: string;
    description: string;
    extra_info: string;
    code: string;
    number_of_days: string;
    map: string;
    xreference: string;
    accommodations: [string];
    images: [string];
    locations: [string];
    to_price: ToPrice;
    from_price: FromPrice;
    itinerary: [string];
    metadata: Metadata;
}

export interface Holidays {
    holiday_id: string;
    item_id: string;
    item_type: string;
    accommodation_id: string;
}

export interface Images {
    id: string;
    asset_id: string;
    title: string;
    alias: string;
    title_alias: string;
    description: string;
    state: string;
    catid: string;
    created_at: string;
    created_by: string;
    modified_at: string;
    Modified_by: string;
    checked_out_by: string;
    checked_out_at: string;
    publish_up: string;
    publish_down: string;
    params: string;
    metakey: string;
    metadesc: string;
    featured: string;
    xreference: string;
    coords: string;
    file_path: string;
    url_path: string;
    file_name: string;
    file_ext: string;
    width: string;
    height: string;
    focus: string;
    tags: string;
    category_alias: string;
    category_title: string;
    link_featured: string;
    original_sid: string;
    sid: string;
    credit: string;
    alt_text: string;
    caption: string;
    metadata: Metadata;
}

export interface Parent {
    id: string;
    parent_id: string;
    name: string;
    title: string;
    level: string;
    coords: string;
    alias: string;
    parent: Parent;
}

export interface Locations {
    id: string;
    parent_id: string;
    coords: string;
    state: string;
    name: string;
    title: string;
    alias: string;
    description: string;
    catid: string;
    level: string;
    featured: string;
    tags: string;
    lft: string;
    rgt: string;
    meta_title: string;
    meta_description: string;
    meta_keywords: string;
    metadata: string;
    text: string;
    jcfields: [string];
    images: [string];
    children: [string];
    parent: Parent;
    type: Type;
}

export interface Roomtypes {
    id: string;
    title: string;
    description: string;
    primary_image: string;
    quantity: string;
    created_by: string;
    created_at: string;
    modified_by: string;
    modified_at: string;
    state: string;
    ordering: string;
    images: [string];
}

export interface Accommodations {
    id: string;
    ordering: string;
    asset_id: string;
    title: string;
    alias: string;
    description: string;
    introduction: string;
    state: string;
    created_at: string;
    created_by: string;
    modified_at: string;
    modified_by: string;
    checked_out_by: string;
    checked_out_at: string;
    publish_up: string;
    publish_down: string;
    params: string;
    version: string;
    metatitle: string;
    metakey: string;
    metadesc: string;
    metadata: string;
    featured: string;
    xreference: string;
    coords: string;
    location_id: string;
    cat_alias: string;
    link_metadata: string;
    holidays: [Holidays];
    images: [Images];
    locations: [Locations];
    prices: [string];
    rooms: [string];
    roomtypes: [Roomtypes];
}

export interface Prices {
    id: string;
    currency_code: string;
    currency_symbol: string;
    value: string;
    previous_value: string;
    additional_value: string;
    spaces: string;
    start_date_offset: string;
    end_date_offset: string;
    version_id: string;
    type: Type;
}

export interface Start {
    date: string;
    timezone_type: number;
    timezone: string;
}

export interface Dates {
    id: string;
    version_id: string;
    start_date: string;
    end_date: string;
    spaces: string;
    capacity: string;
    is_range: string;
    is_private: string;
    name: string;
    code: string;
    state: string;
    prices: [Prices];
    start: Start;
    flags: [string];
}

export interface DefaultVersion {
    id: string;
    name: string;
    title: string;
    state: string;
    description: string;
    extra_info: string;
    code: string;
    number_of_days: string;
    map: string;
    xreference: string;
    accommodations: [string];
    images: [string];
    locations: [string];
    to_price: ToPrice;
    from_price: FromPrice;
    itinerary: [string];
    metadata: Metadata;
}

export interface Categories {
    id: string;
    path: string;
    title: string;
    note: string;
    parent: Parent;
}

export interface Location {
    id: number;
    name: string;
    path: string;
}
export interface CopyItemObject {
    AccomKey_Amenities: [CopyItem];
    AccomMore_Info: [CopyItem];
    AccomFacilities: [CopyItem];
    HolidayMore_Info: [CopyItem];
    Video: [CopyItem];
    HolidayIncluded: [CopyItem];
    HolidayExcluded: [CopyItem];
    HolidayQ_A: [CopyItem];
    HolidayTs_Cs: [CopyItem];
    AccomAmenities: [CopyItem];
    AccomWellness: [CopyItem];
    AccomIntro_Title: [CopyItem];
    AccomDownload: [CopyItem];
    AccomLocation_Text: [CopyItem];
    AccomImage_Section: [CopyItem];
    AccomLocation_Override: [CopyItem];
    HolidayLocation_Override: [CopyItem];
    AccomRoom_Info: [CopyItem];
    HolidayExperience: [CopyItem];
    HolidayHighlights_Images: [CopyItem];
    AccomOpengraph: [CopyItem];
    HolidayOpengraph: [CopyItem];
    HolidayExclusive_Benefit: [CopyItem];
    HolidayInclusion_Summary: [CopyItem];
    HolidayPackage_Page_Intro: [CopyItem];
    HolidayVacayou_Voyager_Club_Exclusive: [CopyItem];
}

export default class PackageService {
    constructor(private apiService: ApiService) {}

    getPackages(perPage = 10, page = 1): Promise<IPackageListItem[]> {
        return this.apiService
            .get<IPackageListResponse>(
                `/packages?perPage=${perPage}&page${page}`
            )
            .then((response) => response.response)
            .then((paginatedResponse) => paginatedResponse.data);
    }

    getPackageDetail(packageAlias: string): Promise<IPackageDetail> {
        return this.apiService
            .get<IPackageDetailResponse>(`/packages/${packageAlias}`)
            .then((response) => response.response);
    }
}
