import axios from "./axios";

export interface VacResponse {
    response: any;
    message: string;
    error?: any;
}

export default class ApiService {
    constructor() {}

    post<T extends VacResponse, P>(url, payload: P): Promise<T> {
        return axios.post(url, payload).then((response) => response.data as T);
    }

    get<T extends VacResponse>(url): Promise<T> {
        return axios.get(url).then((response) => response.data as T);
    }

    delete<T extends VacResponse>(url): Promise<T> {
        return axios.delete(url).then((response) => response.data as T);
    }
}
