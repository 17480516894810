import { loadStripe } from "@stripe/stripe-js";
import { User } from "firebase/auth";

export default class StripeService {
    stripePromise: unknown;
    constructor() {
        // Make sure to call `loadStripe` outside of a component’s render to avoid
        // recreating the `Stripe` object on every render.
        this.stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY);
    }

    // Initiates a Stripe checkout session for a logged in user
    async subscribeToMembership(user: User) {
        // Send data to API endpoint
        const res = await fetch("/api/stripe/subscribeToMembership", {
            body: JSON.stringify({
                customer_email: user.email,
                client_reference_id: user.uid,
                current_path: window.location.pathname,
            }),
            headers: {
                "Content-Type": "application/json",
            },
            method: "POST",
        });

        const { redirect_url } = await res.json();
        window.location = redirect_url;
    }

    // Initiates a Stripe checkout session for a hotel booking
    async hotelCheckout(
        bookingId,
        user: User,
        routerObject,
        hotelDetails,
        selectedRoom,
        bookerInfoState,
        guestInfoState,
        searchFormState
    ) {
        let checkInDate = new Date(searchFormState.checkIn);
        let checkInString = checkInDate.toDateString().substring(4, 15);
        let checkOutDate = new Date(searchFormState.checkOut);
        let checkOutString = checkOutDate.toDateString().substring(4, 15);
        let body = {
            bookingId: bookingId,
            currentPath: routerObject.asPath,
            customerEmail: bookerInfoState.email,
            clientReferenceId: user.uid,
            currency: selectedRoom.Currency,
            name: hotelDetails.Name,
            description:
                "From " +
                checkInString +
                " to " +
                checkOutString +
                " for " +
                (searchFormState.totalAdults + searchFormState.totalChildren) +
                " guests in a " +
                selectedRoom.RoomDescription[0] +
                " room.",
            totalPrice: selectedRoom.TotalPrice,
            tId: selectedRoom.TID,
            bookerInfo: bookerInfoState,
            guestInfo: guestInfoState,
            hotelDetails: hotelDetails,
            searchFormState: searchFormState,
            selectedRoom: selectedRoom,
        };
        console.log("body: ", body);
        // Initiate Stripe checkout session
        // Send data to API endpoint
        const res = await fetch("/api/stripe/hotelCheckout", {
            body: JSON.stringify(body),
            headers: {
                "Content-Type": "application/json",
            },
            method: "POST",
        });

        const { redirect_url } = await res.json();
        window.location = redirect_url;
    }
}
